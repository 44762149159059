var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"采购员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'purchaserId',
              {
                initialValue: _vm.detail.purchaserId,
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]),expression:"[\n              'purchaserId',\n              {\n                initialValue: detail.purchaserId,\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.userList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"采购时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'purchaseTime',
              {
                initialValue: _vm.moment(_vm.detail.purchaseTime),

                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'purchaseTime',\n              {\n                initialValue: moment(detail.purchaseTime),\n\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"申请原因","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'reason',
              {
                initialValue: _vm.detail.reason,

                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'reason',\n              {\n                initialValue: detail.reason,\n\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                initialValue: _vm.detail.remark,
              },
            ]),expression:"[\n              'remark',\n              {\n                initialValue: detail.remark,\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"附件","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('div',{staticStyle:{"margin-top":"-4px"}},[_c('Upload',{attrs:{"list":_vm.fileList},on:{"change":(list) => (_vm.fileList = list)}})],1)])],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"物料清单","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('Goods',{attrs:{"list":_vm.materialList}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/page-header-bg.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("采购申请")])])
}]

export { render, staticRenderFns }